
export const configOpentok = {
    credentials: {
      apiKey: '',
      sessionId: '',
      token: '',
    },
    // A container can either be a query selector or an HTML Element
    streamContainers : (pubSub, type, data, stream)=>({
            publisher: {
              camera: '#cameraPublisherContainer',
              screen: '#screenPublisherContainer',
            },
            subscriber: {
              camera:  '#cameraSubscriberContainer',
              screen: '#screenSubscriberContainer',
            },
          }[pubSub][type]
        )
      ,
    controlsContainer: '#controls',
    packages: [],
    communication: {
      callProperties: {}, // Using default
    },
    textChat: {
      name: '', // eslint-disable-line no-bitwise
      waitingMessage: 'Messages will be delivered when other users arrive',
      container: '#chat',
      alwaysOpen: true,
      appendControl: false
    },
    screenSharing: {
      extensionID: 'plocfffmbcclpdifaikiikgplfnepkpo',
      annotation: false,
      externalWindow: false,
      dev: true,
      screenProperties: {
        insertMode: 'append',
        width: '100%',
        height: '100%',
        showControls: false,
        style: {
          buttonDisplayMode: 'off',
        },
        videoSource: 'window',
        fitMode: 'contain' // Using default
      },
    },
    annotation: {
      absoluteParent: {
        publisher: '.App-video-container',
        subscriber: '.App-video-container'
      }
    }
};


export const setEvents = (otCore, setPublishers, setSubscribers, checkHosterOnline, setEnableShareScreen, prevSpeaker) => {
    let movingAvg = {};
    let audioperstreamer = {};
    let speaker = null;

    const speakerBox = document.getElementById('cameraSpeaker');
    const subscriberBox =  document.getElementById('cameraSubscriberContainer');

    [
        'subscribeToCamera',
        'unsubscribeFromCamera',
        'subscribeToScreen',
        'unsubscribeFromScreen',
        'startScreenShare',
        'endScreenShare',
        'streamCreated',
        'streamDestroyed'
    ].forEach(event => otCore.on(event, (ot) =>{

            // console.log(event, ot)

        if(ot.subscribers){ // |unsubscribeFromCamera
            // console.log(ot)
            setPublishers(ot.publishers)
            setSubscribers(Object.values(ot.subscribers.camera))
            checkHosterOnline(ot.subscribers)
        }

        if(event === 'startScreenShare')
          setEnableShareScreen(true)
        if(event === 'endScreenShare')
          setEnableShareScreen(false)


        if(event==='subscribeToCamera'){

            Object.keys(ot.streams).forEach((tokid, i) => {
                let cam = document.getElementById(ot.streamMap[tokid])
                movingAvg[tokid]=null
                audioperstreamer[tokid] = 0
                if(cam){
                    cam.insertAdjacentHTML('beforeend',
                            `<div class="caption position-absolute">${JSON.parse(ot.streams[tokid].connection.data).name}</div>`)
                    cam.insertAdjacentHTML('beforeend',`<div class="position-absolute text-white" id="vol-${tokid}"></div>`)
                }
            })

            ot.subscriber.off('audioLevelUpdated')
            ot.subscriber.on('audioLevelUpdated',function(event) {
                if (movingAvg[ot.subscriber.streamId] === null || movingAvg[ot.subscriber.streamId] <= event.audioLevel) {
                movingAvg[ot.subscriber.streamId] = event.audioLevel;
                } else {
                movingAvg[ot.subscriber.streamId] = 0.7 * movingAvg[ot.subscriber.streamId] + 0.3 * event.audioLevel;
                }

                // 1.5 scaling to map the -30 - 0 dBm range to [0,1]
                var logLevel = (Math.log(movingAvg[ot.subscriber.streamId]) / Math.LN10) / 1.5 + 1;
                logLevel = Math.round(Math.min(Math.max(logLevel, 0), 1)*100)/100;

                audioperstreamer[ot.subscriber.streamId] = logLevel

                if(logLevel > 0)
                    speaker = Object.keys(audioperstreamer).reduce((a, b) =>
                    audioperstreamer[a] === audioperstreamer[b] ? false :
                        (audioperstreamer[a] > audioperstreamer[b] ? a : b))

                if(speaker&&prevSpeaker!==speaker){
                    prevSpeaker.current=speaker;
                    let tmp_speaker = document.getElementById(`vol-${speaker}`)

                    if(tmp_speaker && speakerBox.firstElementChild)
                        subscriberBox.appendChild(speakerBox.firstElementChild)


                    if(tmp_speaker && 'parentNode' in tmp_speaker)
                        speakerBox.appendChild(tmp_speaker.parentNode)

                }
            })
        }
    }))
}
