import React, { useEffect, useState, useRef } from "react";
import classnames from "classnames";
import { Row, Col, Spinner, Image, Button, Alert } from "react-bootstrap";
import moment from "moment";
import AccCore from "opentok-accelerator-core";
import "opentok-solutions-css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faCamera, faMicrophone, faStopwatch, faChevronRight,
    faShareSquare, faFileAlt, faHandPaper, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { configOpentok, setEvents } from './opentok'
// eslint-disable-next-line no-unused-vars
import OT  from '@opentok/client';

import { Partecipants, Annotations } from './hoster-comp'


let otCore

const VideoChat = ({ parameters }) => {
    const isHoster = parameters.host_name === parameters.user_name
    const [statusCall, setStatusCall] = useState({ connected: false, active: false })
    const prevSpeaker = useRef('');

    /*    */
    useEffect(() => {
        if (!parameters.opentok || !parameters.opentok.apiKey) return
            otCore = new AccCore({
                ...configOpentok,
                credentials: {
                    apiKey: parameters.opentok.apiKey,
                    sessionId: parameters.opentok.sessionId,
                    token: parameters.opentok.token
                },
                textChat: {...configOpentok.textChat, name: parameters.user_name},
                communication: { callProperties: { user_id: 1 } },
                packages: isHoster ? ['screenSharing', 'textChat', 'annotation'] : ['textChat']
            });

        console.log('Connect OT')
        try{
            otCore.connect()
                .then((ot) => {
                    setStatusCall({ ...statusCall, connected: true })
                })
        } catch(e){
            console.log('error connection')
        }
        const checkHosterOnline = (subscribers) => {
            // console.log('checkHosterOnline', subscribers.camera)
            setHosterIsOnline(!subscribers
                ? false
                : Object.values(subscribers.camera)
                    .some(({ stream }) => {
                        return JSON.parse(stream.connection.data).name === parameters.host_name})
            )
        }

        setEvents(otCore, setPublishers, setSubscribers, checkHosterOnline, setEnableShareScreen, prevSpeaker)


        return () => {
            closeConnection()
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [(parameters.opentok||{}).token]); //!opentok

    useEffect(()=>{

        if(parameters.mute_start && statusCall.active && !isHoster && statusControl.localAudioEnabled)
            toggleLocalAudio(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusCall.active])

    const currenVideochat = {}
    const videolist = useRef()
    const [ , setPublishers] = useState(null)
    const [subscribers, setSubscribers] = useState([])
    const [enableShareScreen, setEnableShareScreen] = useState(false)
    const [statusControl, setStatusControl] = useState({ localAudioEnabled: true, localVideoEnabled: true })
    const [hosterIsOnline, setHosterIsOnline] = useState(false)
    const [annotations, setAnnotations] = useState(false)
    const [showHand, setShowHand] = useState(false)


    if (!currenVideochat) return (<div>videochat not found</div>)


    const startCall = () => {
        // console.log(otCore)
        otCore.startCall().then((ot) => {  //{ publishers, subscribers, meta }
            setStatusCall({ ...statusCall, active: true })
            document.getElementById('cameraPublisherContainer')
                .insertAdjacentHTML('beforeend',
                    `<div class="caption position-absolute">${parameters.user_name}</div>`)

        }).catch(error => {
            closeConnection()
            otCore = null
            setStatusCall({ ...statusCall, active: false })
            console.error('error call', error)
        });
    }

    const endCall = () => {
        console.log('endCall', otCore)
        if (otCore && 'endCall' in otCore)
            try { otCore.endCall(); } catch (error) { console.log('error end call', error) }
        setStatusCall({ ...statusCall, active: false })
        setShowHand(false)
        prevSpeaker.current='';
        window.location.reload();
    }

    const closeConnection = () => {
        console.log('close', otCore)
        endCall()
        otCore.off()
        window.onbeforeunload = null
        otCore.session.disconnect()
        otCore.session.destroy()
    }

    const toggleLocalAudio = ({ force = null } = {}) => {
        let set_to = force !== null ? force : !statusControl.localAudioEnabled;
        console.log(force, set_to)
        otCore.toggleLocalAudio(set_to);
        setStatusControl({ ...statusControl, localAudioEnabled: set_to });
        // if (showHand) {
        //     setShowHand(false)
        // }
    }

    const toggleLocalVideo = () => {
        otCore.toggleLocalVideo(!statusControl.localVideoEnabled);
        setStatusControl({ ...statusControl, localVideoEnabled: !statusControl.localVideoEnabled });
    }

    const showMyHand = () => {
        if (!showHand) toggleLocalAudio({ force: false })
        setShowHand(!showHand)
    }

    const countsubscribs = subscribers ? subscribers.length : 0

    return (
        <div className="videochat-container">
            {!statusCall.connected && connectingMask()}
            {statusCall.connected && !statusCall.active && startCallMask(startCall, statusCall.active, false)}

            <Row className={classnames("overflow-auto", 'h-100', { 'd-none': !statusCall.connected || !statusCall.active })}>
                <Col className="px-0">

                    <Row className="pl-3 pb-3 header-videochat overflow-hidden mr-0">
                        <Col sm={2} className={classnames("info p-lg-3 p-md-1 text-center mr-0", { 'd-none': false })} >
                            <InfoCall host_name={parameters.host_name} timeLeft={parameters.left_time} />
                        </Col>
                        <Col className="p-0 ml-0 v-scrool d-flex flex-nowrap overflow-hidden" ref={videolist}>
                            <div id="cameraPublisherContainer" className={classnames({ 'd-none': false })} />
                            <div id="cameraSubscriberContainer" />
                        </Col>
                        <Col sm={1} className={classnames("justify-content-center flex-column align-items-center",
                                                    { 'd-flex': countsubscribs > 3, 'd-none': (countsubscribs < 4) })}>
                            <Button onClick={() => {
                                videolist.current.scrollLeft += videolist.current.firstElementChild.offsetWidth
                            }}>
                                <FontAwesomeIcon icon={faChevronRight} className="d-block" />
                            </Button>
                            <Button onClick={() => {
                                videolist.current.scrollLeft -= videolist.current.firstElementChild.offsetWidth
                            }}>
                                <FontAwesomeIcon icon={faChevronLeft} className="d-block" />
                            </Button>
                        </Col>
                    </Row>

                    <div className="d-flex justify-content-center body-videochat overflow-hidden position-relative App-video-container">
                        <div id="screenPublisherContainer" />
                        <div id="screenSubscriberContainer" />

                        <div className={classnames("ot-video-speaker")} id="cameraSpeaker" />
                        {!isHoster && !hosterIsOnline && (<Alert variant="warning" className="ot-video-wait-host"> Waiting for the host</Alert>)}
                    </div>

                    <div className="footer-videochat d-flex align-items-center">
                        <Button variant="danger" className="px-4 mx-4" onClick={endCall}>
                            <b>End Call</b>
                        </Button>
                        <div id="controls" className={classnames("controller", { 'd-none': false })}>

                            <Button
                                className={classnames({ 'active': !statusControl.localAudioEnabled })}
                                data-label={statusControl.localAudioEnabled ? "Mute" : "Unmute"}
                                id="mic" onClick={toggleLocalAudio}>
                                <FontAwesomeIcon icon={faMicrophone} />
                            </Button>
                            <Button
                                className={classnames({ 'active': !statusControl.localVideoEnabled })}
                                data-label="Video"
                                id="webcam" onClick={toggleLocalVideo}>
                                <FontAwesomeIcon icon={faCamera} />
                            </Button>

                            { isHoster && <Button className={classnames('sharescreen',{'active': enableShareScreen})}
                                            data-label="Share" id="startScreenSharing">
                                <FontAwesomeIcon icon={faShareSquare} />
                            </Button>}

                            {isHoster && <Button className={classnames('annotation', { 'active': annotations })}
                                data-label="Notes"
                                onClick={() => { setAnnotations(!annotations) }}>
                                <FontAwesomeIcon icon={faFileAlt} />
                            </Button>}

                            {false && !isHoster && hosterIsOnline && <Button
                                className={classnames('hands', { 'active': showHand })}
                                data-label="Hand"
                                onClick={() => { showMyHand(); }}>
                                <FontAwesomeIcon icon={faHandPaper} />
                            </Button>}
                        </div>
                    </div>
                </Col>

                <Col md={3} className="px-0 d-none d-md-block">
                    {isHoster && <Partecipants subscribers={subscribers} currenVideochat={currenVideochat} otCore={otCore} />}
                    {isHoster && annotations && <Annotations id={parameters.id} setAnnotations={setAnnotations} host_name={parameters.host_name} />}
                    <div id="chat" style={{height: `calc(${isHoster?'50%':'100%'} - 47px)`}} >
                        <h3 className="p-2 bg-primary">Chat</h3>
                    </div>
                </Col>
            </Row>
        </div>
    )
};

export default VideoChat;
/*  */


/*  */
const InfoCall = ({ host_name, timeLeft }) => {
    const [countdown, setCountdown] = useState(timeLeft)
    timeLeft = moment(isNaN(timeLeft)?(timeLeft||'').replace(' ','+') : parseInt(timeLeft))
    useEffect(() => {
        let interval = setInterval(() => { setCountdown(timeLeft - moment()) }, 1000)
        return () => { clearInterval(interval) }
    }, [timeLeft])

    return (
        <div>
            <FontAwesomeIcon icon={faUser} className="d-block mx-auto mb-1" />
            <b>Host: {host_name}</b>
            <div className={classnames({ 'text-red': timeLeft.isBefore(moment().add(1, 'm')) })}>
                <FontAwesomeIcon icon={faStopwatch} className="d-block mx-auto mb-1 mt-2" />
                <b>Time left: {!timeLeft.isBefore(moment()) ? moment(countdown).format('mm:ss') : 'Expired'}</b>
            </div>
        </div>
    )
}

const connectingMask = () => (
    <div className="wrap-landing w-100 align-items-center align-self-center">
        <Image className="img-fluid w-25 mx-auto pt-5 d-block" src={process.env.REACT_APP_LOGO} />
        <div className="mx-auto mt-4 w-25 text-center">
            <Spinner animation="border" variant="dark" autoFocus style={{ left: '48.5%' }} />
            <span className=" mt-5 pt-2">connecting...</span>
        </div>

    </div>
)

const startCallMask = (start, actived, minimize) => {

    return (
        <div className="wrap-landing w-100 align-items-center align-self-center">
            <Image className="img-fluid w-25 mx-auto pt-5 mb-3 d-block" src={process.env.REACT_APP_LOGO} />
            <div className={classnames("mt-5 mx-auto absolute-center w-50")}>
                {actived && <Spinner animation="border" variant="dark" autoFocus style={{ left: '48.5%' }} />}
                {!actived && <Button variant="secondary" autoFocus={true} className="px-5 d-block mx-auto" onClick={e => {
                    e.target.disabled = true;
                    start()
                }}>Click to Start Call </Button>}
                <span className=" text-center d-block mx-auto mt-5 w-75">
                    MAKE SURE TO ALLOW CAMERA AND
                    MICROPHONE PERMISSIONS FOR THIS SITE
            </span>
            </div>
        </div>
    )
}
