import { useEffect, useState } from 'react';
import { Container, Alert } from 'react-bootstrap';
import VideoChat from './components/videochat';
import 'react-toastify/dist/ReactToastify.css';
import './style/app.scss';
import { ToastContainer } from 'react-toastify';


import {generateToken} from './api'

// ?id=1&host-name=Pippo+Pluto&user-name=Pippo+Pluto&left-time=2020-11-27T18:00:00+01&max-limit=5&mute-start=1

function App() {
  const [parameters, setParameters] = useState(false)
  const [apiError, setApiError] = useState(false)

  useEffect(()=>{
    const url = new URL(window.location.href);
    let params = [];
    ['host-name', 'user-name', 'left-time', 'max-limit', 'mute-start', 'host-force', 'id'].forEach(p => {
      params[p.replace('-','_')] = url.searchParams.get(p)
    })

    if(!params.host_name) setApiError('Url incomplete')

    setParameters(params)

    reqOpetokSession(
      params.id,
      params.user_name,
      setApiError).then(ris=>{
        if(!ris.error)
          setParameters({...params, opentok: ris})

    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // console.log(parameters)

  return (
    <Container fluid style={{minHeight:'100vh'}}>
      {!apiError&&parameters&&<VideoChat parameters={parameters} />}
      {apiError&&<Alert variant="warning" className="alert-center">{apiError}</Alert>}
      <ToastContainer />
    </Container>
  );
}

export default App;


const reqOpetokSession = async (id, name, setApiError) => {
  try {
      const resp = await generateToken(id, name)
      return resp.data
  } catch (err) {
      // Handle Error Here
      console.error('error api', err);
      setApiError('API connection error');
      return {error: err}
  }
};
