import React, { useEffect, useRef, useState } from "react";
import { Button, ListGroup, Form, Modal } from "react-bootstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandPaper } from '@fortawesome/free-solid-svg-icons'
import { toast } from "react-toastify";
import {sendNote} from '../api'


export const Partecipants = ({subscribers=[], currenVideochat, otCore}) => {
    const partecipants = (subscribers||[]).map(({stream, streamId})=>(stream?{key: streamId, ...JSON.parse(stream.connection.data)}:false))
    const prevHands = usePrevious((currenVideochat.hands||[])) || []
    if(prevHands.length < (currenVideochat.hands||[]).length)
        toast.info('Show of hand')

    // fa-hand-paper
    return (
        <div className="partecipants h-50 bg-white position-relative">
            <h3 className="p-2 bg-primary">Participants</h3>
            <div className="part-body overflow-auto">
                <ListGroup variant="flush">
                    {partecipants.map(p=>( p.name ?
                        (<ListGroup.Item key={p.name}>
                            {p.name} {(currenVideochat.hands||[]).indexOf(p.name)!==-1&&
                                            <FontAwesomeIcon className="float-right text-primary" icon={faHandPaper} />}
                        </ListGroup.Item>) : (<div />)
                    ))}
                </ListGroup>
            </div>
            <div className="position-absolute w-100 text-center" style={{bottom:'0.5rem'}}>
                {false&&<Button size="sm" className="text-white" onClick={()=>{
                            partecipants.map(({key})=>otCore.toggleRemoteAudio(key,false))
                        }} >
                    Mute all
                </Button>}
            </div>
        </div>
    )
}


export const Annotations = ({id, host_name}) => {
    const note = useRef()
    const email = useRef()
    const [show, setShow] = useState(false)
    const saveNote = () => {
        sendNote(id, {note: note.current.value, email: email.current.value, host: host_name}).then(()=>{
            setShow(false);
            toast.info(`Sended to ${email.current.value}`)
        })
    }
    return (
        <div className="position-absolute w-100 h-50 bg-white" style={{bottom:'1px',zIndex:10, maxWidth:'100%'}}>
            <h3 className="p-2 bg-primary">Notes</h3>
            <div style={{height:'calc(100% - 5.5rem)'}}>
                <Form.Control ref={note} as="textarea"
                    className="h-100 border-0 shadow m-1 w-100"
                    placeholder="add new note.." style={{maxWidth:'calc(100% - 1rem)'}} />
                <Button className="w-100" onClick={()=>{ setShow(true); }}>Save</Button>
            </div>

            <Modal show={show} onHide={()=>{ setShow(false); }}>
                <Modal.Header closeButton>
                <Modal.Title>Save Note</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Send by email?
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Your Email address</Form.Label>
                        <Form.Control ref={email} type="email" placeholder="Enter email" />
                    </Form.Group>
                    </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={()=>{ setShow(false); }}>
                    Wait
                </Button>
                <Button variant="primary" onClick={()=>{ saveNote(); }}>
                    Send
                </Button>
                </Modal.Footer>
            </Modal>

        </div>
    )
}


function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
}
